import React, { FunctionComponent } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Box, Image, Text } from "@chakra-ui/react"
import Img from "gatsby-image"

type Props = {
  blog_top?: boolean
}

/**
 * ブログの最新数件を返却する。
 * （ここは何件返すのが他の出しいのだろうか）
 * @returns ブログ
 */
export const Blog: FunctionComponent<Props> = props => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: [date], order: DESC }) {
        nodes {
          date(formatString: "YYYY.MM.DD")
          modified(formatString: "YYYY.MM.DD")
          title
          excerpt
          slug
          featuredImage {
            node {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)

  //highlight-line
  return (
    <>
      {/* highlight-start */}
      {data.allWpPost.nodes.map(node => (
        <Link to={props.blog_top ? node.slug : "blog/" + node.slug}>
          <Box padding="6" boxShadow="lg" bg="white" mb={3}>
            <Text fontSize="xl" fontWeight="bold">
              {node.title}
            </Text>
            <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            {node.date}
          </Box>
        </Link>
      ))}
      {/* highlight-end */}
    </>
  )
}

import { Box, Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react"
import React from "react"
import FrontLayout from "../../components/FrontLayout"
import { Blog } from "../../components/Blog/top"

export default function AboutPage() {
  return (
    <FrontLayout>
      <Box bg="blue.900" w="100%" p={4} color="white">
        <Heading>Nolvem Garage</Heading>
      </Box>
      <Blog blog_top />
    </FrontLayout>
  )
}
